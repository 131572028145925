import React from "react";
import { deleteDetailsByShortCode, getDetailsByShortCode } from "./api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export const useDetailsHook = (shortCode) => {
  const navigate = useNavigate();
  const [detailsOfUrl, setUrlDetails] = React.useState(undefined);

  const deleteUrlDetails = React.useCallback((shortCode) => {
    (async () => {
      const [data, err] = await deleteDetailsByShortCode(shortCode);
      if (err) console.error(err);
      if (data)
        toast("🗑️ the link was removed", {
          duration: 2000,
          style: {
            backgroundColor: "#003049",
            color: "#fff",
            fontSize: 13,
          },
        });
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const [data, err] = await getDetailsByShortCode(shortCode);
      if (err) {
        toast("😖 this link may not exist", {
          duration: 2000,
          style: { backgroundColor: "#ffe6a7", fontSize: 13},
        });
        navigate("/error");
      }
      if (data) setUrlDetails(data.details);
    })();

    return () => {
      setUrlDetails(undefined);
    };
  }, []);

  return {
    detailsOfUrl,
    deleteUrlDetails,
  };
};
